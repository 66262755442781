<template>
  <div class="notification__wrapper">
    <transition>
      <div v-if="showNotification" class="notification">
        <ButtonComponent
          ref="closeButton"
          button-type="look-like-link"
          class="notification__button--close"
          data-cy="notification__close-button"
          :clickhandler="reset"
        >
          <span class="visually-hidden">Schließen</span>
          <Icon name="close-simple" height="32" />
        </ButtonComponent>
        <ErrorMessage
          v-if="error"
          data-cy="notification-error"
          :html="errorMessage"
          @click="clickLink"
        ></ErrorMessage>
        <div
          v-if="success"
          class="notification__wrapper--success alert alert--success"
        >
          <ImageComponent
            v-if="showSuccessImage"
            class="wrapper__image"
            :src="`/assets/webp_images/email_verification/${$brand.code}-success-small.webp`"
            width="100"
            height="100"
          />
          <div
            data-cy="notification-success"
            @click="clickLink"
            v-html="successMessage"
          />
        </div>
        <div
          v-if="info"
          data-cy="notification-info"
          class="alert alert--highlight"
          :clickhandler="clickLink"
          v-html="infoMessage"
        ></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { scrollTop } from 'src/lib/scroll_to.js'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import ErrorMessage from 'src/components/common/error_message/error_message.vue'
import Icon from '../icon/icon.vue'
import ImageComponent from 'src/components/common/image/image.vue'

const baseState = () => ({
  error: false,
  errorMessage: undefined,
  success: false,
  successMessage: undefined,
  info: false,
  infoMessage: undefined,
  persistPageChangeOnce: false,
  showSuccessImage: false,
  hideReportLink: false
})

export default {
  name: 'NotificationComponent',
  components: {
    ErrorMessage,
    ButtonComponent,
    ImageComponent,
    Icon
  },
  data() {
    return baseState()
  },
  computed: {
    showNotification() {
      return this.error || this.success || this.info
    }
  },
  watch: {
    showNotification(val) {
      this.$store.commit('notification/setNotificationState', val)
    }
  },
  created() {
    this.$bus.on('error', this.onError)
    this.$bus.on('success', this.onSuccess)
    this.$bus.on('info', this.onInfo)
    this.$bus.on('page-change', this.onPageChange)
    this.$bus.on('hide-notifications', this.reset)
  },
  beforeUnmount() {
    this.$bus.off('error', this.onError)
    this.$bus.off('success', this.onSuccess)
    this.$bus.off('info', this.onInfo)
    this.$bus.off('page-change', this.onPageChange)
    this.$bus.off('hide-notifications', this.reset)
  },
  methods: {
    reset() {
      Object.assign(this.$data, baseState())
    },
    onError(message, persistPageChangeOnce, hideReportLink) {
      this.reset()
      this.error = true
      this.errorMessage = message
      this.persistPageChangeOnce = persistPageChangeOnce ? true : false
      if (hideReportLink) {
        this.hideReportLink = true
      }

      this.setA11yAnnouncement(message, 'assertive')
      scrollTop()
    },
    onSuccess(message, persistPageChangeOnce, showSuccessImage) {
      this.reset()
      this.success = true
      this.successMessage = message
      this.persistPageChangeOnce = persistPageChangeOnce ? true : false
      if (showSuccessImage) {
        this.showSuccessImage = true
      }

      this.setA11yAnnouncement(message, 'polite')
      scrollTop()
    },
    onInfo(message, persistPageChangeOnce) {
      this.reset()
      this.info = true
      this.infoMessage = message
      this.persistPageChangeOnce = persistPageChangeOnce ? true : false
      this.setA11yAnnouncement(message, 'polite')
      scrollTop()
    },
    onPageChange() {
      if (!this.persistPageChangeOnce) {
        this.reset()
      } else {
        this.persistPageChangeOnce = false
      }
    },
    clickLink(event) {
      if (
        event.target.tagName === 'A' &&
        event.target.classList.contains('router-link')
      ) {
        const path = event.target.getAttribute('href')

        if (path && path.length > 0) {
          event.preventDefault()
          event.stopPropagation()
          this.$router.push(path)
        }
      }
    },
    setA11yAnnouncement(message, politness) {
      this.$announcer.set(message, politness)
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  position: relative;
}

.notification__wrapper {
  width: 100%;
  background-color: var(--background-primary);

  .notification {
    @include full-width;

    padding: 20px;
    padding-bottom: 0;
  }
}

.notification__wrapper--success {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoint($from-tablet) {
    flex-direction: row;
  }
}

.wrapper__image {
  align-self: center;
  margin-top: 20px;

  @include breakpoint($from-tablet) {
    margin: 0 20px;
    flex-direction: row;
  }
}

.notification__button--close {
  position: absolute;
  top: 30px;
  right: 21px;
  color: var(--root-color);
}
</style>
